<template>
  <main class="document-page page">
    <BreadcrumbsComponent
      :title="document.title"
      :links="[{ title: 'Документы', to: { name: 'documents' } }]"
    />
    <div class="container-padding">
      <div class="document-page__inner">
        <aside class="document-aside">
          <div class="document-aside__item" v-if="document.created_at">
            <span class="document-aside__item-title">Документ от:</span>
            <span class="document-aside__content">
              {{ document.created_at | humanDate }}
            </span>
          </div>
          <div class="document-aside__item" v-if="document.document">
            <a
              :href="document.document.path"
              :download="document.document.origin_name"
              class="btn btn--main btn--md"
            >
              <IconComponent name="receive-square" />
              <span>
                Скачать
                <template v-if="document.document.type && document.document.size">
                  ({{ document.document.type }}, {{ document.document.size }})
                </template>
              </span>
            </a>
          </div>
        </aside>
        <iframe
          v-if="document.document"
          :src="`https://docs.google.com/gview?url=${
            $store.state._env.MEDIA_ENDPOINT + document.document.path
          }&embedded=true`"
          class="document-page__iframe"
        />
        <h2 v-else>В данный момент документ не загружен</h2>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import DOCUMENTS_OPEN_PAGE from "gql/pages/DocumentsOpenPage.graphql";

export default {
  name: "DocumentsOpenPage",
  metaInfo() {
    const meta = this.$store.state.documents.documents_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: DOCUMENTS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("documents/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    document() {
      return this.$store.state.documents.documents_item;
    },
  },
  components: {
    BreadcrumbsComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/components/doc-aside.styl"
.document-page {

  &__inner {
    display grid
    padding 50px 0 0 0
    grid-template-columns 328px 1fr
    grid-gap 30px
    align-items start
    +below(1024px) {
      grid-template-columns 1fr
    }
  }

  &__iframe {
    border none
    height 100vh
    width 100%
    border-radius 10px
    +below(1024px) {
      height 100vw
    }
  }
}
</style>
